/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ACTIVE_TAB_EVENT } from '../../../constants/feeds';
import { ChevronRight } from '../../../icons/ChevronRight';
import { TrendingSpinner } from '../../../icons/TrendingSpinner';
import SplashCard from '../../FeedCard/SplashCard';
import FeedsButton from '../../FeedsButton';
import { useTrackingContext } from '../../../hooks';
import styles from './trendingPosts.module.scss';

export const TrendingPosts = ({ className = '', data = { items: [] } }) => {
  const [primaryItem, ...secondaryItems] = data.items;
  const { trackContentAction } = useTrackingContext();

  const trackingData = {
    unit_name: 'splash',
    position_in_unit: 0,
    subunit_name: 'trending',
    subunit_type: 'package',
  };

  const dispatchActiveTabEvent = () => {
    const setActiveTabEvent = new CustomEvent(ACTIVE_TAB_EVENT, { detail: 'tab_trending' });
    window.dispatchEvent(setActiveTabEvent);
  };

  const onCtaClick = useCallback(() => {
    dispatchActiveTabEvent();
    trackContentAction({
      ...trackingData,
      action_type: 'navigate',
      action_value: 'trending',
      item_name: 'see_all',
      item_type: 'text',
      position_in_subunit: null,
    });
  }, [trackContentAction]);

  const onCtaBottomClick = useCallback(() => {
    dispatchActiveTabEvent();
    trackContentAction({
      ...trackingData,
      action_type: 'navigate',
      action_value: 'trending',
      item_name: 'see_all_trending',
      item_type: 'text',
      position_in_subunit: 4,
    });
  }, [trackContentAction]);

  if (!primaryItem?.content) {
    return null;
  }

  return (
    <section className={`${className}`}>
      <header className={styles.headline}>
        <h2>Trending Now</h2>
        <button className={styles.cta} onClick={onCtaClick}>
          See All
          <ChevronRight />
        </button>
        <TrendingSpinner className={styles.spinner} />
      </header>
      <ol className={styles.trendingPosts}>
        <li className={`${styles.primaryCard}`}>
          <SplashCard
            className='primaryCard'
            item={primaryItem?.content}
            index={1}
            imageRatioTablet='1/1'
            imageRatioDesktop='1/1'
            isTrackable={true}
            trackingData={{ ...trackingData, ...primaryItem?.content?.trackingData, position_in_subunit: 0 }}
          />
        </li>
        {secondaryItems.map(({ content }, index) => (
          <li key={content.id} className={`${styles.secondaryCard}`}>
            <SplashCard
              item={content}
              index={index+2}
              isPrimary={false}
              imageRatioDesktop="1/1"
              isTrackable={true}
              trackingData={{ ...trackingData, ...content.trackingData, position_in_subunit: index+1 }}
            />
          </li>
        ))}
      </ol>
      <FeedsButton
        className={`${styles.ctaBottom}`}
        onClick={onCtaBottomClick}
        title={<>See All Trending <ChevronRight /></>}
      />
    </section>
  );
};

TrendingPosts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default TrendingPosts;
